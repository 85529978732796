body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  overflow: hidden;
}

.background {
  width:100%;
  height:100vh;
  display:flex;
  flex-direction: column; 
  align-items: center;
  background-image: url("/public/images/iStock-1214528330.jpg");
  background-size:cover;
  background-position:center;
  background-repeat:no-repeat;
}

.container {
  width: 625px;
  position: absolute;
  bottom: 0;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10px;
}

.glassmorphism {
  box-shadow: 0 8px 32px 0 rgba(31,38,135,.37);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  background-color:rgba(255,255,255,.4);
  border: 1px solid rgba(255,255,255,.18);
  border-radius: 10px;
}

.introduction {
  padding: 20px;
}

.title {
  font-weight: 200;
  font-size: 40px;
}

.shine {
  background-color: black;
  font-weight: 600;
  background-size: 200% 100%;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  transition: background 1.2s;
}

.shine:hover {
  background-position: -120% 0;
}

.row {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.col {
  flex: 0 0 auto;
  width: 50%;
}

.col .icon {
  display: flex;
  justify-content: center;
}

.col .icon .icon-component {
  font-size: 8vw;
}

.contact-box {
  margin: 10px;
  position: fixed;
  top: 0;
  right: 0;
}

.contact-qrcode {
  width: 100px;
  height: 100px;
  padding: 10px 10px 0 10px;
}

.contact-label {
  font-size: 20px;
  font-weight: 200;
  margin: 0;
  padding: 0;
  text-align: center;
}

@media only screen and (max-width: 625px) {
  .container {
    width: 95%;
  }
}

@media only screen and (min-width: 624px) {
  .shine {
    background-image: linear-gradient(
      -75deg,
      transparent 0,
      transparent 5%,
      rgba(255,255,255,.5) 5%,
      rgba(255,255,255,.5) 10%,
      transparent 10%,
      transparent 100%
    );
  }
}

@media (prefers-color-scheme: dark) {
  .glassmorphism {
    background-color:rgba(0,0,0,.4);
    border: 1px solid rgba(0,0,0,.18);
  }

  .title {
    color: white;
  }

  .shine {
    background-color: white;

  }

  .contact-label {
    color: white;
  }
}

@media only screen and (min-width: 624px) and (prefers-color-scheme: dark) {
  .shine {
    background-image: linear-gradient(
      -75deg,
      transparent 0,
      transparent 5%,
      rgba(0,0,0,.5) 5%,
      rgba(0,0,0,.5) 10%,
      transparent 10%,
      transparent 100%
    );
  }
}
